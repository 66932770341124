<template>
  <div>
    <b-overlay
      :show="show"
      rounded="sm"
      no-fade
    >
      <b-container class="bv-example-row">
        <b-row>
          <b-col>
            <!-- bg-variant="light" -->
            <b-card
              no-body
              class="card-browser-states m-5"
            >
              <b-card-header>
                <div class="d-flex align-content-center justify-content-between w-100">
                  <!-- <b-card-title class="d-flex align-content-center justify-content-between w-100"> -->
                  <b-link
                    class="ml-3"
                    to="/"
                  >
                    <b-avatar
                      class="i-avatar-cl"
                      rounded="sm"
                      size="35px"
                      :src="objNew.clubs ? objNew.clubs[0].club_image_media_file.url_thumb : ''"
                    />
                  </b-link>
                  <b-link
                    class="mr-3"
                    to="/"
                  >
                    <b-avatar
                      class="i-avatar-cl float-right"
                      rounded="sm"
                      size="39px"
                      src="@/assets/images/ostrail/logo.png"
                    />
                  </b-link>
                  <!-- </b-card-title> -->
                </div>
                <!-- <button-download /> -->

              </b-card-header>
              <!-- body -->
              <b-card-body>
                <div class="blog-detail-wrapper">
                  <b-row>
                    <!-- blogs -->
                    <b-col cols="12">
                      <!-- img-height="600" -->
                      <b-card class="pl-3 pr-3">
                        <b-card-title><h1>{{ objNew.title }}</h1></b-card-title>
                        <b-card-sub-title v-if="objNew.club">
                          <p>{{ objNew.club.club_name }}</p>
                        </b-card-sub-title>
                        <b-media no-body>
                          <b-media-aside
                            vertical-align="center"
                            class="mr-50"
                          >
                            <b-avatar
                              v-if="objNew.user"
                              size="24"
                              :src="objNew.user.avatar_file"
                            />
                          </b-media-aside>
                          <b-media-body>
                            <small class="text-muted mr-50">{{ $t('generic.by') }}</small>
                            <small>
                              <b-link class="text-body">{{ objNew.user.full_name }}</b-link>
                            </small>
                            <span class="text-muted ml-75 mr-50">|</span>
                            <small class="text-muted">{{ objNew.init_publication_date | formatDate }}</small>
                          </b-media-body>
                        </b-media>
                        <div class="my-1 py-25">
                          <b-link
                            v-for="tag in objNew.tags"
                            :key="tag"
                          >
                            <b-badge
                              pill
                              class="mr-75"
                              variant="light-primary"
                            >
                              {{ tag }}
                            </b-badge>
                          </b-link>
                        </div>
                        <div
                          class="blog-content mb-3"
                        >
                          <rich-text-edit
                            :text-one="$t('generic.description')"
                            :text-two="'description'"
                            :agreements="objNew.description"
                            :updating-data="false"
                            :apply-edition-x="'edit'"
                            :read-only-text="true"
                          />
                        </div>
                        <div
                          v-if="objNew.head_content_type_key == 'FILE'"
                        >
                          <div v-if="validatorImageAllowed(objNew.head_content.mime_type)">
                            <b-img
                              fluid-grow
                              alt="Fluid-grow image"
                              :src="objNew.head_content.url_absolute"
                            />
                          </div>
                          <div v-else>
                            <b-container fluid>
                              <b-row align-h="center">
                                <b-col>
                                  <b-embed
                                    type="iframe"
                                    aspect="embed-responsive embed-responsive-16by9 embed-responsive-item"
                                    class="text-center"
                                    :src="objNew.head_content.url_absolute"
                                    allowfullscreen
                                  />
                                  <!-- </div> -->
                                </b-col>
                              </b-row>
                            </b-container>
                          </div>
                        </div>
                        <div
                          v-if="objNew.head_content_type_key == 'URL'"
                        >
                          <div class="container-fluid">
                            <div class="row">
                              <div class="col-lg-12">
                                <div class="embed-responsive embed-responsive-16by9">
                                  <iframe
                                    :src="objNew.head_content_url"
                                    width="100%"
                                    height="100%"
                                    frameborder="0"
                                    webkitallowfullscreen
                                    mozallowfullscreen
                                    allowfullscreen
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="blog-content mt-4">
                          <rich-text-edit
                            :text-one="$t('generic.description')"
                            :text-two="'content'"
                            :agreements="objNew.content"
                            :updating-data="false"
                            :apply-edition-x="'edit'"
                            :read-only-text="true"
                          />
                        </div>
                        <hr class="my-2">

                        <div class="d-flex align-items-center justify-content-between">
                          <div class="d-flex align-items-center" />

                          <!-- dropdown -->
                          <div v-if="objNew.share_post === 1">
                            <dropdown-share
                              :obj-new="objNew"
                            />
                          </div>
                          <!--/ dropdown -->
                        </div>
                      </b-card>
                    </b-col>
                    <!--/ blogs -->
                  </b-row>
                  <!--/ blogs -->
                </div>
                <!--/ content -->
              </b-card-body>
            <!--/ body -->
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </b-overlay>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import checkPermission from '@/auth/permissions'
import { validatorImageAllowed } from '@core/utils/validations/validators'
import RichTextEdit from '@/views/news/components/RichTextEdit.vue'
import DropdownShare from './components/DropdownShare.vue'

export default {
  components: {
    RichTextEdit,
    DropdownShare,
  },
  errorCaptured() {
    return false
  },
  data() {
    return {
      name: 'N/A',
      show: false,
    }
  },
  computed: {
    ...mapGetters({
      objNew: 'noticeStore/notice',
    }),
  },
  mounted() {
    if (this.checkPermission(['VIEW_NOTICES'])) {
      if (this.$route.params.id == null) {
        this.$router.push({ name: 'news' })
      }
      this.fetchNewData(this.$route.params.id)
    } else {
      this.$router.push({ name: 'not-authorized' })
    }
  },
  methods: {
    checkPermission,
    validatorImageAllowed,
    ...mapActions({
      // fetchNewById: 'news/fetchNewById',
      fetchDataNoticePublicById: 'noticeStore/fetchDataPublicById',
    }),
    async fetchNewData(id) {
      this.show = true
      await this.fetchDataNoticePublicById(id).then(response => {
        this.show = false
      }).catch(error => {
        this.show = false
        this.responseCatch(error)
        this.$router.push({ name: 'auth-login' })
      })
    },
  },
}

</script>
<style lang="scss">
.ql-align-right {
  text-align: right;
}
.ql-align-center {
  text-align: center;
}
.ql-align-left {
  text-align: left;
}
</style>
