<template lang="">
  <div>
    <div class="blog-detail-share">
      <b-dropdown
        variant="link"
        toggle-class="p-0"
        no-caret
        right
      >
        <template #button-content>
          <feather-icon
            size="21"
            icon="Share2Icon"
            class="text-body"
          />
        </template>
        <b-dropdown-item
          target="_blank"
          :href="`http://www.facebook.com/sharer.php?s=100&p[url]=${baseURL}/public-news/${objNew.slug}&p[title]=${objNew.title}&p[summary]=${objNew.description}&p[images][0]=${objNew.image.url_absolute}`"
        >
          <feather-icon
            icon="FacebookIcon"
            size="18"
          />
        </b-dropdown-item>
        <b-dropdown-item
          target="_blank"
          :href="`mailto:?subject=${objNew.title}&body=${objNew.image.url_absolute}%0A${objNew.description}%0AURL:${baseURL}/public-news/${objNew.slug}`"
        >
          <feather-icon
            icon="MailIcon"
            size="18"
          />
        </b-dropdown-item>
        <b-dropdown-item
          target="_blank"
          :href="`https://twitter.com/intent/tweet?text=${objNew.title}%0AURL:${baseURL}/public-news/${objNew.slug}`"
        >
          <feather-icon
            icon="TwitterIcon"
            size="18"
          />
        </b-dropdown-item>
        <b-dropdown-item
          target="_blank"
          :href="`https://www.linkedin.com/sharing/share-offsite/?url=${baseURL}/public-news/${objNew.slug}&title=${objNew.title}&summary=${baseURL}/public-news/${objNew.slug}`"
        >
          <feather-icon
            icon="LinkedinIcon"
            size="18"
          />
        </b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    objNew: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      baseURL: window.location.origin,
      // baseURL: 'https://ostrail.com',
    }
  },
}
</script>
<style lang="">

</style>
